import SanityImage from "@sanity-image";
import RichText from "components/rich-text";
import MarkdownParser from "components/rich-text/markdown-parser";
import { Link } from "src/navigation";
import { getUrlString } from "helpers/helpers";
import RedundantLink from "components/accesibility/redundant-link";
import styles from "./content-card.module.scss";
import { useLocale } from 'next-intl';
import Badge from "components/badge";
import { CSSProperties, ReactNode } from "react";
import { SanityInternalLink } from "@metmuseum/types";
import { Image } from "@sanity/types";
const ContentCard = ({
  title,
  badges,
  description,
  textDescription,
  image,
  link,
  internalLink,
  eyebrow,
  children,
  //Optional Children to appear below Description
  className = "",
  cardBodyPadding = 0,
  hasBorder = false,
  invertColors = false,
  style = {},
  sideBySide = false,
  partners = []
}: ContentCardProps) => {
  const lang = useLocale();
  const cardLink = getUrlString({
    link,
    internalLink
  }) || null;
  const classString = `${styles.card} ${className}
        ${invertColors ? styles.invertColors : ""}
				${sideBySide ? styles.sideBySide : ""}
        ${hasBorder ? styles.hasBorder : ""}`;
  return <div style={style} className={classString} data-sentry-component="ContentCard" data-sentry-source-file="index.tsx">
			<div className={`${styles.imageWrapper}`}>
				{cardLink && <RedundantLink locale={lang} href={cardLink} />}
				{image && <SanityImage image={image} fill={true} sizes="(max-width: 960px) 100vw, 50vw" />}
				{!!badges?.length && <div className={styles.badges}>
						{badges.map(badge => {
          return <Badge color={badge?.color || "currentColor"} key={badge.text} text={badge.text} icon={badge.icon} />;
        })}
					</div>}
			</div>

			<div className={styles.body} style={({
      "--body-padding": cardBodyPadding
    } as CSSProperties)}>
				<div className={styles.eyebrow}>{eyebrow}</div>
				<div className={styles.heading} role="heading" aria-level={2}>
					{cardLink ? <Link locale={lang} href={cardLink}>
							<MarkdownParser>{title}</MarkdownParser>
						</Link> : <MarkdownParser>{title}</MarkdownParser>}
				</div>
				<div>
					{!!description?.length && <div className={styles.description}>
						<RichText value={description} />
					</div>}
					{!!textDescription && <div className={styles.description}>
						<MarkdownParser>{textDescription}</MarkdownParser>
					</div>}
					{!!partners?.length && <div className={styles.partners}>
							<div style={{
            display: 'flex',
            gap: '4px',
            flexWrap: 'wrap'
          }}>
								{partners.map(partner => {
              const partnerContent = <div style={{
                display: 'inline-block'
              }}>
											<SanityImage image={partner.partnerImage} sizes="160px" alt={`The logo of ${partner.displayName}`} style={{
                  height: '40px',
                  width: 'auto'
                }} />
										</div>;
              return partner?.link?.href ? <Link key={partner._id} href={partner.link.href} passHref>
											{partnerContent}
										</Link> : <div key={partner._id}>
											{partnerContent}
										</div>;
            })}
							</div>
						</div>}
					{children}
				</div>
			</div>
		</div>;
};
export interface ContentCardProps {
  cardBodyPadding?: any;
  children?: ReactNode[];
  className?: string;
  description?: any[] | string; //Portable Text or plaintext
  textDescription?: string;
  eyebrow?: string;
  hasBorder?: boolean;
  image?: Image;
  badges?: {
    text: string;
    icon?: any;
    color?: string;
  }[];
  invertColors?: boolean;
  internalLink?: SanityInternalLink;
  link?: string;
  title?: string;
  style?: object;
  sideBySide?: boolean;
  partners?: any[];
}
export default ContentCard;